





















































































































































































































































































































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import PageTitle from "@/components/General/PageTitle.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Product } from "@/models/product.interface";
import { Investment } from "@/models/investment.interface";
import { Detail, ProductDetail } from "@/models/detail.interface";
import { Deadline as DeadlineInterface } from "@/models/deadline.interface";
import DeadlineInput from "@/components/Input/Deadline.vue";
import DetailInput from "@/components/Input/Detail.vue";
import FilePicker from "@/components/Input/FilePicker.vue";
import { Term } from "@/models/term.interface";
import { Vue, Watch } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import { Category } from "@/models/category.interface";
import CategoryBreadcrumbs from "@/components/Layout/Common/CategoryBreadcrums.vue";
import { File as FileInterface } from "@/models/file.interface";
import moment from "moment";
import { EditorImages } from "@/mixins/editor-images";
import { FileCheck } from "@/mixins/file-check";
import TermForm from "@/components/Layout/Backoffice/TermForm.vue";
import DetailForm from "@/components/Layout/Backoffice/DetailForm.vue";
import { StyleCheck } from "@/mixins/style-check";
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    PageTitle,
    Loader,
    Empty,
    DetailHeader,
    DeadlineInput,
    DetailInput,
    FilePicker,
    VueEditor,
    CategoryBreadcrumbs,
    TermForm,
    DetailForm,
    Carousel,
    Slide,
  },
})
export default class ProductForm extends mixins(
  FormValidations,
  Navigation,
  EditorImages,
  FileCheck,
  StyleCheck
) {
  dialogNewTerm = false;
  private closeDialogForNewTerm(term: Term) {
    this.dialogNewTerm = false;
    if (term.id) {
      this.loadTerms();
    }
  }

  dialogNewDetail = false;
  private closeDialogForNewDetail(detail: Detail) {
    this.dialogNewDetail = false;
    if (detail.id) {
      this.loadDetails();
    }
  }

  update = false;
  $refs!: {
    productForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  product: Product & { file: File | null; files: File[] } & {
    hide_quantity: boolean;
  } = {
    id: undefined,
    name: "",
    description: "",
    price: 0,
    quantity: 0,
    check_users: false,
    hide_quantity: false,
    file: null,
    files: [],
    details: [],
    status: {
      name: "",
    },
  };

  investment: Investment = {
    id: undefined,
    roi: 0,
    is_a_bucket: false,
    is_manual: false,
    deadlines: [
      {
        percentage: 100,
        price: 0,
        term: undefined,
      },
    ],
  };
  product_details: ProductDetail[] = [
    {
      value: "",
      terms: [],
      files: [],
      detail: undefined,
    },
  ];
  category: Category = { id: 0, name: "", icon: "" };
  staff = "";
  terms: Term[] = [];
  details: Detail[] = [];
  categories: { all: Category[]; tree: Category[] } = {
    all: [],
    tree: [],
  };
  filePreview = "";
  filesPreview: string[] = [];
  productFile: File | null = null;
  productFiles: File[] = [];
  showErrorDescription = false;
  showErrorPercentage = false;
  total_price = 0;

  @Watch("product.hide_quantity")
  private hideQuantityHandler(value: boolean) {
    if (value) this.investment.is_a_bucket = false;
  }

  private get orderDeadlines() {
    const aux = this.investment.deadlines;
    let result: DeadlineInterface[] = [];

    let sort = true;
    aux.forEach((element) => {
      if (element.term == undefined) {
        sort = false;
      }
    });

    if (sort) {
      result = aux.sort((a, b) => {
        let valueA = (
          a.term?.max_num !== null ? a.term?.max_num : a.term?.num
        ) as never;
        let valueB = (
          b.term?.max_num !== null ? b.term?.max_num : b.term?.num
        ) as never;

        return this.whichIsLongest(
          { value: valueA, type: a.term?.type as string },
          { value: valueB, type: b.term?.type as string }
        );
      });
    } else {
      result = aux;
    }

    return result;
  }

  private whichIsLongest(
    dateA: { value: number; type: string },
    dateB: { value: number; type: string }
  ) {
    const checkA = dateA.value * this.amountOfDaysDependingOnType(dateA.type);
    const checkB = dateB.value * this.amountOfDaysDependingOnType(dateB.type);
    if (checkA > checkB) {
      return 1;
    } else if (checkA < checkB) {
      return -1;
    } else {
      return 0;
    }
  }

  private amountOfDaysDependingOnType(type: string): number {
    switch (type) {
      case "days":
        return 1;
      case "weeks":
        return 7;
      case "months":
        return moment().daysInMonth();
      case "years":
        return 365;
      default:
        return 0;
    }
  }

  private async created() {
    this.resetProductProgress();
    this.loader = true;

    await this.loadTerms();
    await this.loadDetails();
    await this.loadCategories();

    if (this.$route.params.id) {
      this.update = true;
      await this.$store
        .dispatch("products/getProduct", this.$route.params.id)
        .catch(() => {
          const Error: Notification = {
            message: this.$t("Product.fetchError.productById", {
              name: this.$route.params.id,
            }) as string,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });

      this.product = await this.$store.getters["products/getProduct"];
      Vue.set(
        this,
        "filePreview",
        this.product.photo_preview ? this.product.photo_preview.url : ""
      );
      const filesPreview: string[] = [];
      this.product.files.forEach((file) => {
        if (file.url) filesPreview.push(file.url);
      });
      if (filesPreview.length > 0) Vue.set(this, "filesPreview", filesPreview);
      Vue.set(this, "category", this.product.category);
      Vue.set(this, "investment", this.product.investment);
      const productDetails: ProductDetail[] = [];
      this.product.details.forEach((detail) => {
        let productDetail = { ...detail };
        productDetail.terms = [];
        if (detail.terms) {
          detail.terms.forEach((term) => {
            productDetail.terms.push({ term: term });
          });
          productDetail.terms = productDetail.terms.sort((a: any, b: any) => {
            const max_a = a.term.max_num ? a.term.max_num : a.term.num;
            const max_b = b.term.max_num ? b.term.max_num : b.term.num;
            if (max_a > max_b) {
              return 1;
            }
            if (max_a < max_b) {
              return -1;
            }
            return 0;
          });
        }
        productDetails.push(productDetail);
      });
      Vue.set(this, "product_details", productDetails);
    } else {
      this.update = false;
    }

    this.setNavigation({
      previousRoute: "/staff/products",
      icon: "mdi-clipboard-list",
      title: this.product.name
        ? this.$tc("Product.editTitle")
        : this.$tc("Product.createTitle"),
    });
    this.loader = false;
  }

  get boughtProduct() {
    return (this.product as any).products_bill?.length > 0;
  }

  @Watch("total_price")
  watchTotalPrice() {
    if (this.product.quantity) {
      this.product.price =
        Number(this.total_price) / Number(this.product.quantity);
    }
  }

  @Watch("product.price")
  watchPrice() {
    if (this.product.quantity) {
      this.total_price =
        Number(this.product.price) * Number(this.product.quantity);
    }
  }

  @Watch("product.quantity")
  watchQuantity() {
    if (this.product.price) {
      this.total_price =
        Number(this.product.price) * Number(this.product.quantity);
    } else if (this.total_price) {
      this.product.price =
        Number(this.total_price) / Number(this.product.quantity);
    }
  }

  private get productProgress(): boolean {
    return this.$store.getters["products/getProductProgress"];
  }

  private addDeadline(key: number) {
    let newDeadline: DeadlineInterface = {
      percentage: parseFloat((100 - this.actualPercentage).toFixed(2)),
      price: 0,
      term: undefined,
    };
    this.investment.deadlines.splice(key + 1, 0, newDeadline);
  }

  private deleteDeadline(key: number) {
    this.investment.deadlines.splice(key, 1);
  }

  private addDetail() {
    let newDetail: ProductDetail = {
      value: "",
      terms: [],
      files: [],
      detail: undefined,
    };
    this.product_details.push(newDetail);
  }

  private deleteDetail(key: number) {
    this.product_details.splice(key, 1);
  }

  private get actualPercentage(): number {
    let percentage = 0;
    this.investment.deadlines.forEach((deadline) => {
      percentage = percentage + Number(deadline.percentage);
    });
    return percentage;
  }

  private get totalROI(): number {
    return (
      Number(this.product.price) +
      Number(this.product.price) * (Number(this.investment.roi) / 100)
    );
  }

  private async loadTerms() {
    this.terms = await this.$store
      .dispatch("terms/getAssignableTerms")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Terms.fetchError.terms"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async loadDetails() {
    this.details = await this.$store
      .dispatch("details/getAssignableDetails")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Terms.fetchError.terms"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async loadCategories() {
    this.categories = await this.$store
      .dispatch("categories/getAssignableCategories")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Categories.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private activeTerms(deadline_id: number): Term[] {
    let activeTerms: Term[] = [];
    this.terms.forEach((term) => {
      let used = false;
      this.investment.deadlines.forEach((deadline, index) => {
        if (term.id == deadline.term?.id && deadline_id !== index) {
          used = true;
        }
      });
      if (!used) {
        activeTerms.push(term);
      }
    });
    return activeTerms;
  }

  private activeDetails(detail_id: number): Detail[] {
    let activeDetails: Detail[] = [];
    this.details.forEach((detail) => {
      let used = false;
      this.product_details.forEach((product_detail, index) => {
        if (
          product_detail.detail &&
          detail.id == product_detail.detail.id &&
          detail_id !== index
        ) {
          used = true;
        }
      });
      if (!used) {
        activeDetails.push(detail);
      }
    });
    return activeDetails;
  }

  private changeDetail(detail: Detail, detail_id: number) {
    Vue.set(this.product_details, detail_id, detail);
  }

  @Watch("productFile")
  previewFile(file: File): void {
    if (file != null) {
      this.filePreview = URL.createObjectURL(file);
    } else {
      if (!this.product.id) {
        this.filePreview = "";
        this.productFile = null;
      }
    }
  }

  @Watch("productFiles")
  previewFiles(files: Array<File>): void {
    this.filesPreview = [];
    if (files.length > 0) {
      files.forEach((file: File) => {
        this.filesPreview.push(URL.createObjectURL(file));
      });
    }
  }

  @Watch("product.description")
  checkDescription(): void {
    if (this.product.description != "") {
      this.showErrorDescription = false;
    } else {
      this.showErrorDescription = true;
    }
  }

  @Watch("actualPercentage")
  private watchActualPercentage(): void {
    if (this.actualPercentage == 100) {
      this.showErrorPercentage = false;
    } else {
      this.showErrorPercentage = true;
    }
  }

  private customFilter(item: Category, queryText) {
    const text: string = item.name as string;
    const searchText = queryText.toLowerCase();

    return text.toLowerCase().indexOf(searchText) > -1;
  }

  private get userID(): string {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.id;
    } else {
      return "";
    }
  }

  private async resetProductProgress() {
    await this.$store.dispatch("products/resetProductProgress");
  }

  progressMessage = "";

  private async createProduct() {
    this.loading = true;
    if (this.product.description == "") {
      this.showErrorDescription = true;
    }
    if (this.actualPercentage !== 100) {
      this.showErrorPercentage = true;
    }
    if (
      this.$refs.productForm.validate() &&
      !this.showErrorDescription &&
      !this.showErrorPercentage
    ) {
      this.progressMessage = this.$tc("Product.progress.product");
      this.investment.deadlines = this.orderDeadlines;
      let product = await this.$store
        .dispatch("products/createProduct", {
          product: this.product,
          investment: this.investment,
          details: this.product_details,
          category: this.category,
          staff: { id: this.userID },
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Product.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };
          this.$store.dispatch("notifications/showNotification", Error);
          this.loading = false;
        });
      this.resetProductProgress();
      this.progressMessage = this.$tc("Product.progress.files");
      const payloadProductFiles = new FormData();
      payloadProductFiles.append(
        "DATA",
        JSON.stringify({
          product: {
            id: product.id,
          },
        })
      );
      if (this.productFile) {
        payloadProductFiles.append(
          this.$constants.FILE_CATEGORY.PRODUCT_PREVIEW,
          this.productFile as File
        );
      }
      if (this.productFiles && this.productFiles.length > 0) {
        this.productFiles.forEach((file) => {
          payloadProductFiles.append(
            this.$constants.FILE_CATEGORY.PRODUCT,
            file as File
          );
        });
      }
      this.$store
        .dispatch("products/createProductFiles", payloadProductFiles)
        .then(async () => {
          this.resetProductProgress();
          this.progressMessage = this.$tc("Product.progress.details");
          let promises: Promise<any>[] = [];
          this.product_details.forEach((product_detail) => {
            if (
              product_detail.detail &&
              product_detail.detail.type == this.$constants.DETAIL_TYPES.FILE
            ) {
              const payloadProductDetailFiles = new FormData();
              payloadProductDetailFiles.append(
                "DATA",
                JSON.stringify({
                  product: {
                    id: product.id,
                  },
                  detail: {
                    id: product_detail.detail.id,
                  },
                })
              );
              if (product_detail.files) {
                product_detail.files.forEach((file) => {
                  payloadProductDetailFiles.append(
                    this.$constants.FILE_CATEGORY.PRODUCT_DETAIL,
                    file as File
                  );
                });
              }

              promises.push(
                this.$store
                  .dispatch(
                    "products/createDetailFiles",
                    payloadProductDetailFiles
                  )
                  .catch(() => {
                    const Error: Notification = {
                      message: this.$tc("Products.error"),
                      timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
                      top: true,
                      bottom: false,
                      left: false,
                      right: false,
                      currentPath: this.$route.fullPath,
                      error: true,
                    };
                    this.$store.dispatch(
                      "notifications/showNotification",
                      Error
                    );
                    this.loading = false;
                  })
              );
            }
          });
          await Promise.all(promises);
          this.resetProductProgress();
          this.progressMessage = "";
          this.loading = false;
          this.navigate("/staff/products");
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Products.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };
          this.$store.dispatch("notifications/showNotification", Error);
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private async updateProduct() {
    this.loading = true;
    if (this.product.description == "") {
      this.showErrorDescription = true;
    }
    if (this.actualPercentage !== 100) {
      this.showErrorPercentage = true;
    }
    if (
      this.$refs.productForm.validate() &&
      !this.showErrorDescription &&
      !this.showErrorPercentage
    ) {
      this.progressMessage = this.$tc("Product.progress.product");
      try {
        this.investment.deadlines = this.orderDeadlines;
        await this.$store.dispatch("products/updateProduct", {
          product: this.product,
          investment: this.investment,
          details: this.product_details,
          category: this.category,
        });
        this.resetProductProgress();
        this.progressMessage = this.$tc("Product.progress.status");
        if (!this.boughtProduct) {
          await this.$store.dispatch("products/updateProductStatus", {
            id: this.product.id,
            status: this.product.status?.name,
          });
        }
        this.resetProductProgress();
        this.progressMessage = this.$tc("Product.progress.files");
        const payloadProductFiles = new FormData();
        payloadProductFiles.append(
          "DATA",
          JSON.stringify({
            product: {
              id: this.product.id,
            },
          })
        );
        if (this.productFile) {
          payloadProductFiles.append(
            this.$constants.FILE_CATEGORY.PRODUCT_PREVIEW,
            this.productFile as File
          );
        }
        if (this.productFiles && this.productFiles.length > 0) {
          this.productFiles.forEach((file) => {
            payloadProductFiles.append(
              this.$constants.FILE_CATEGORY.PRODUCT,
              file as File
            );
          });
        }
        if (
          this.productFile ||
          (this.productFiles && this.productFiles.length > 0)
        ) {
          await this.$store.dispatch(
            "products/createProductFiles",
            payloadProductFiles
          );
        }
        this.resetProductProgress();
        this.progressMessage = this.$tc("Product.progress.details");
        let promises: Promise<any>[] = [];
        this.product_details.forEach((product_detail) => {
          if (
            product_detail.detail &&
            product_detail.detail.type == this.$constants.DETAIL_TYPES.FILE
          ) {
            const payloadProductDetailFiles = new FormData();
            payloadProductDetailFiles.append(
              "DATA",
              JSON.stringify({
                product: {
                  id: this.product.id,
                },
                detail: {
                  id: product_detail.detail.id,
                },
              })
            );
            let previousFiles = false;
            if (product_detail.files) {
              product_detail.files.forEach((file) => {
                if ((file as FileInterface).id) {
                  previousFiles = true;
                } else {
                  payloadProductDetailFiles.append(
                    this.$constants.FILE_CATEGORY.PRODUCT_DETAIL,
                    file as File
                  );
                }
              });
            }

            if (!previousFiles) {
              promises.push(
                this.$store.dispatch(
                  "products/createDetailFiles",
                  payloadProductDetailFiles
                )
              );
            }
          }
        });
        await Promise.all(promises);
        this.resetProductProgress();
        this.progressMessage = "";
        this.loading = false;
        this.navigate("/staff/products");
      } catch (error) {
        const Error: Notification = {
          message: this.$tc("Product.error"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };
        this.$store.dispatch("notifications/showNotification", Error);
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }
}
